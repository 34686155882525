import EducationCard from './EducationCard';
import '../../styles/global.css'

function Education() {
  return (<>
    {/* Education Section*/}
    <section>
      <h2 className="text-secondary fw-bolder mb-4">Education</h2>
      <EducationCard />
      <EducationCard />
    </section>
  </>);
}

export default Education;
