import '../styles/global.css'
import rimu1 from "../assets/rimu_1.jpg"
import rimu2 from "../assets/rimu_2.jpg"
import rimu3 from "../assets/rimu_3.jpg"
import rimu4 from "../assets/rimu_4.jpg"
import rimu5 from "../assets/rimu_5.jpg"

function Temp() {
  return (<>
    <img src={rimu1} alt="" />
    <img src={rimu2} alt="" />
    <img src={rimu3} alt="" />
    <img src={rimu4} alt="" />
    <img src={rimu5} alt="" />
  </>);
}

export default Temp;
