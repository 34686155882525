import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Projects from './pages/Projects';
import Resume from './pages/Resume';
import Contact from './pages/Contact';
import Head from './components/Head';
import Temp from './pages/Temp';
import './styles/global.css'
import Home from './pages/Home';


const App = () => {
  return (<>
    <Head />
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/projects" element={<Projects />}></Route>
        <Route path="/resume" element={<Resume />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        {/* 상단에 위치하는 라우트들의 규칙을 모두 확인, 일치하는 라우트가 없는경우 처리 */}
        {/* <Route path="*" element={<NotFound />}></Route> */}
        <Route path="/temprimu" element={<Temp />}></Route>
      </Routes>
    </BrowserRouter>
  </>);
};

export default App;