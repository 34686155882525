import '../../styles/global.css'

function EducationCard() {
  return (<>
    <div className="card shadow border-0 rounded-4 mb-5">
      <div className="card-body p-5">
        <div className="row align-items-center gx-5">
          <div className="col text-center text-lg-start mb-4 mb-lg-0">
            <div className="bg-light p-4 rounded-4">
              <div className="text-secondary fw-bolder mb-2">
                2015 - 2017
              </div>
              <div className="mb-2">
                <div className="small fw-bolder">Barnett College</div>
                <div className="small text-muted">Fairfield, NY</div>
              </div>
              <div className="fst-italic">
                <div className="small text-muted">Master's</div>
                <div className="small text-muted">Web Development</div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Delectus laudantium, voluptatem quis repellendus eaque sit
              animi illo ipsam amet officiis corporis sed aliquam non
              voluptate corrupti excepturi maxime porro fuga.
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}

export default EducationCard;
