import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Experience from '../containers/Resume/Experience';
import Education from '../containers/Resume/Education';
import SkillsList from '../containers/Resume/SkillsList';
import Subtitle from '../components/Subtitle';
import '../styles/global.css'

function Resume() {
  return (<>
    <div className="d-flex flex-column h-100 bg-light">
      <main className="flex-shrink-0">
        <Navigation />
        {/* Page Content*/}
        <div className="container px-5 my-5">
          <Subtitle />
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-11 col-xl-9 col-xxl-8">
              <Experience />
              <Education />
              <SkillsList />
              <SkillsList />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  </>);
}

export default Resume;
