import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import Header from '../components/Header';
import Section from '../containers/Home/Section';

import '../styles/global.css'

function Home() {
  return (<>
    <div className="d-flex flex-column h-100">
      <main className="flex-shrink-0">
        <Navigation />
        <Header />
        <Section />
      </main>
      <Footer />
    </div>
  </>);
}

export default Home;
