import '../../styles/global.css'
import ExperienceCard from './ExperienceCard';

function Experience() {
  return (<>
    <section>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h2 className="text-primary fw-bolder mb-0">Experience</h2>
        {/* Download resume button*/}
        {/* Note: Set the link href target to a PDF file within your project*/}
        <a className="btn btn-primary px-4 py-3" href="#!">
          <div className="d-inline-block bi bi-download me-2" />
          Download Resume
        </a>
      </div>
      <ExperienceCard />
      <ExperienceCard />
    </section>
  </>);
}

export default Experience;
