import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import Subtitle from '../components/Subtitle';
import ProjectCard from '../containers/Projects/ProjectCard';
import '../styles/global.css'

function Projects() {
  return (<>
    <div className="d-flex flex-column h-100 bg-light">
      <main className="flex-shrink-0">
        <Navigation />
        {/* Projects Section*/}
        <section className="py-5">
          <div className="container px-5 mb-5">
            <Subtitle />
            <div className="row gx-5 justify-content-center">
              <div className="col-lg-11 col-xl-9 col-xxl-8">
                <ProjectCard />
                <ProjectCard />
              </div>
            </div>
          </div>
        </section>
        {/* Call to action section*/}
        <section className="py-5 bg-gradient-primary-to-secondary text-white">
          <div className="container px-5 my-5">
            <div className="text-center">
              <h2 className="display-4 fw-bolder mb-4">
                Let's build something together
              </h2>
              <a
                className="btn btn-outline-light btn-lg px-5 py-3 fs-6 fw-bolder"
                href="/contact"
              >
                Contact me
              </a>
            </div>
          </div>
        </section>
      </main>
      {/* Footer*/}
      <Footer />
      {/* Bootstrap core JS*/}
      {/* Core theme JS*/}
    </div>
  </>);
}

export default Projects;
