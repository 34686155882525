
function Head() {
  return (<>
    <meta name="description" content="" />
    <meta name="author" content="" />
    {/* Favicon*/}
    <link rel="icon" type="image/x-icon" href="assets/favicon.ico" />
    {/* Custom Google font*/}
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
    <link
      href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap"
      rel="stylesheet"
    />
    {/* Bootstrap icons*/}
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css"
      rel="stylesheet"
    />
    {/* Core theme CSS (includes Bootstrap)*/}
    {/* <link href="css/styles.css" rel="stylesheet" /> */}
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css"
      rel="stylesheet"
      integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH"
      crossOrigin="anonymous"
    />

  </>);
}

export default Head;
